import React from "react";
import Layout from "../../../other/layout";
import TradeShowsEvents from "../../../components/AC-StaticPages/sales-resources/trade-shows-events/TradeShowsEvents";
import SEO from "../../../other/seo";

export default function tradeShowsEvents() {
  return (
    <Layout>
      <SEO title="Trade Shows Events" />

      <TradeShowsEvents />
    </Layout>
  );
}
